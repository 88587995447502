import { Col, List, Row, Space } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FancyInput } from 'components/atoms/Inputs';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const BusinessList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const { abbyCadabbyParams } = useAppSelector((state) => state);

  const { data, isLoading, isFetching } = useGetBusinessNameConatinsQuery(abbyCadabbyParams);

  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerSearch(undefined));
    };
  }, [dispatch]);

  const businessList = data?.data;

  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businessList) {
      for (const business of businessList) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]] as any;
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businessList, customerSearch]);

  /* ******************** Render ******************** */
  return (
    <div style={{ marginTop: 10 }}>
      <Row justify="space-between">
        <Space>
          <Col>
            <AntPageTitle text="Customers" />
          </Col>
          <Col>
            <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
          </Col>
        </Space>
      </Row>
      <List
        loading={{
          indicator: <BlockLoader direction="loader loader--slideUp" />,
          size: 'large',
          spinning: isLoading || isFetching
        }}
        dataSource={filteredData}
        renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
        rowKey={(item: any): string => item.id}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};
